import React from "react"
import Layout from '../components/Layout';
import Header from '../components/Header';
import Footer from '../components/Footer';
import SocialLinks from '../components/SocialLinks';


const DonatePage = () => (

  <Layout>
    <Header />

    <section id="purpose" className="projects-section bg-light">
      <div className="container">
        <h2> DONATE</h2>
        <a href="https://stripe.com/get-started?utm_campaign=paid_brand-US_Search_Brand_Stripe-1803852691&utm_medium=cpc&utm_source=google&ad_content=344587125079&utm_term=stripe%20payment%20processing&utm_matchtype=e&utm_adposition=&utm_device=c&gclid=CjwKCAjwv4_1BRAhEiwAtMDLssKY1IrjEz51gbIBeuDTRzMO7AEFd3Lb9T0b2wfb4QXHb96sbMOjxBoCiQ0QAvD_BwE"> Stripe</a>
      </div>
    </section>
    <SocialLinks />
    <Footer />
  </Layout>
)
export default DonatePage
